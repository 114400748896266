


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { waitTimer } from '@/core/pendings'
import { RouteNames } from '@/router'
const SVGSad = () => import('@/assets/images/svg/sad.svg?inline')

@Component({
  components: {
    SVGSad
  }
})
export default class ScannerFailed extends Vue {
  @Prop({
    type: String,
    default: 'Не удалось распознать штрих-код,\n попробуйте ещё раз.'
  })
  text!:string

  created() {
    waitTimer(20e3, 1e3,
      () => this.$route.name !== RouteNames.scannerFailed)
      .catch(() => {
        if (this.$route.name === RouteNames.scannerFailed) {
          this.$router.back()
        }
      })
  }
}
